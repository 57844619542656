.App, .Gallery, .Audio, .Contact {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.headers {
  font-family: "arial-black",'Chunkfive', arial, helvetica, sans-serif;
}

.image-home {
  width: 706px;
  /* border: 20px, solid, #6F6F6F; */
  vertical-align: center;
}

.image-backing {
  width: 709px;
  /* height: 482.5px; */
  background: #6F6F6F;
  margin: 0 auto;
  justify-content: center;
  /* padding: 6px 0; */
  border-top: 4px solid #6F6F6F;
  border-left: 4.5px solid #6F6F6F;
  border-right: 4px solid #6F6F6F;
  border-bottom: 2px solid #6F6F6F;
  text-align: center;
  /* border: 50px, solid, #6F6F6F; */
}

.nav-elem {
  text-decoration: none;
  color: #DDDDDD;
  display: inline;
  padding: 16px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.107em;
}


.home-description {
  text-align: left;
  padding-left: 10rem;
  padding-right: 10rem;
  width: 40%;
  line-height: 1.5rem;
}

.popup {
  width: 50%;
  height: 35%;
  background: #FFFFFF;
  position: absolute;
  top: 40%;
  left: 25%;
  border-radius: 5px;
  text-align: center;
}

@media (max-width: 414px) {
  .image-home {
    width: 100%;
    /* border: 20px, solid, #6F6F6F; */
    vertical-align: center;
  }

  .image-backing {
    width: 414px;
    border-top: 3px solid #6F6F6F;
    border-left: 0px solid #6F6F6F;
    border-right: 0px solid #6F6F6F;
    border-bottom: 0px solid #6F6F6F;
  }

  .home-description {
    text-align: left;
    padding-left: 3rem;
    padding-right: 3rem;
    width: 80%;
    line-height: 1.5rem;
  }

  .popup {
    width: 80%;
    height: 45%;
    background: #FFFFFF;
    position: absolute;
    top: 40%;
    left: 10%;
    border-radius: 5px;
    text-align: center;
  }
  .nav-elem {
    padding: 10px;
    font-size: 12px;
  }
}

@media (max-width: 375px) {

  .image-backing {
    width: 375px;
    border-top: 3px solid #6F6F6F;
    border-left: 0px solid #6F6F6F;
    border-right: 0px solid #6F6F6F;
    border-bottom: 0px solid #6F6F6F;
  }
  .nav-elem {
    padding: 8px;
    font-size: 10px;
  }
}

@media (max-width: 360px) {

  .image-backing {
    width: 360px;
    border-top: 3px solid #6F6F6F;
    border-left: 0px solid #6F6F6F;
    border-right: 0px solid #6F6F6F;
    border-bottom: 0px solid #6F6F6F;
  }

  .nav-elem {
    padding: 10px;
  }
}

p, label {
  font-family: helvetica;
  color: #5E5E5E;
  font-size: 16px;
  letter-spacing: 0.107em;
}

.contact-form {

}

.contact-item {
  display: inline-block;
  width: 80%;
  padding: 1rem;
  margin: 1rem;
}

.submit {
  border-radius: 2rem;
  font-family: helvetica;
  color: #5E5E5E;
  font-size: 16px;
  letter-spacing: 0.107em;
  width: 80%;
  padding: 1rem;
  margin: 1rem;
}

.contact-container {
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
}

@media (max-width: 414px) {
  .contact-container {
    display: block;
    padding-left: 10%;
    padding-right: 10%;
  }
}


.contact-side {
  display: inline;
  width: 50%;
  text-align: left;
}

h4 {
  font-family: helvetica;
  color: #1B1B1B;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.107em;
}

.headers-smaller {
  display: flex;
  text-align: center;
  margin: 0 auto;
  flex-direction: row;
}

@media (min-width: 963px) {
  .headers-smaller:before,
  .headers-smaller:after {
     content: "";
     flex: 1 1;
     border-bottom: 1px solid #000;
     margin-bottom: auto;
     margin-top: auto;
     margin-left: 10rem;
     margin-right: 10rem;
  }
}

@media (max-width: 962px) {
  .headers-smaller {
    display: block;
  }
}

.bottom-image {
  width: 100%;
  /* height: 200px; */
  object-fit: contain;
}

h2 {
  font-family: "arial-black";
  letter-spacing: 0.107em;
}

.nav-container {
  background-color: rgb(111, 111, 111);
  display: flex;
  font-family: helvetica;
  justify-content: center;
}

.desktop-nav {
  padding: 24px;
}

@media (max-width: 414px) {
  .desktop-nav {
    padding: 14px;
  }
}

.podcast-links {
  text-decoration: none;
  text-decoration: underline;
  color: #555555;
  font-size: 18px;
  font-weight: 700;
}

.media-item {
  color: #555555;
  font-size: 18px;
  font-weight: 700;
}

.important-podcast {
  font-weight: 900;
}

.audio-containers {
  padding: 1rem;
}

.gallery-pics {
  width: 600px;
  padding: 1rem;
}

@media (max-width: 620px) {
  .gallery-pics {
    width: 300px;
  }
}

h3 {
  font-family: helvetica;
  color: #1B1B1B;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0.107em;
  padding: 1rem;
  clear: both;
}

.inner {
  top: -20%;
  position: relative;
  /* position: absolute; */
}

.x {
  /* float: right; */
  padding: 0px;
  cursor: pointer;
  left: 46%;
  top: -12%;
  position: relative;
  font-size: 30px;

}

.button {
  border-color: black;
  border-width: thin;
  background: #FFFFFF;
  border-radius: 2rem;
  padding: 12px;
  width: 30%;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  letter-spacing: 0.107em;
  font-family: helvetica;
}

.button:hover {
  background: #AAAAAA;
  color: #EEEEEE;
}

@media (max-width: 414px) {
  .button {
    width: 60%;
  }
}

.shading {
  background: #111111CC;
  width: 100%;
  height: 1000%;
  top: 0%;
  position: absolute;
  z-index: -1;
}
